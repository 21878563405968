
import Vue from "vue"
import { customAlphabet } from "nanoid"
import {
  Swiper, Navigation, Mousewheel, Keyboard, A11y, Autoplay,
} from "swiper/js/swiper.esm"
import type { SwiperOptions } from "swiper"
import "swiper/css/swiper.min.css"

Swiper.use([
  Navigation,
  Mousewheel,
  Keyboard,
  A11y,
  Autoplay,
])

export default Vue.extend({
  props: {
    options: {
      type: Object as VuePropType<Record<string, any>>,
      default: () => ({}),
    },
    noNavigation: { type: Boolean, default: false },
    navDisabledClass: { type: String, default: "" },
    watchOptions: { type: Boolean, default: true },
    notClient: { type: Boolean, default: false },
  },

  data () {
    return {
      swiper: null as Nullable<Swiper>,
      prevId: "",
      nextId: "",
      isHidden: !this.notClient,
    }
  },

  mounted () {
    if (!this.noNavigation) this.setNavIds()
    setTimeout(() => {
      const { reinit } = this
      reinit()
      this.isHidden = false
      if (this.watchOptions)
        this.$watch("options", () => reinit(), { deep: true })
    }, 100)
  },

  methods: {
    setNavIds () {
      const nanoid = customAlphabet("abcdef");
      [this.prevId, this.nextId] = [...Array(2)].map(() => nanoid())
    },

    reinit () {
      this.swiper?.destroy(true, false)

      const containerEl = this.$refs.container
      if (!containerEl) return

      // https://github.com/nolimits4web/swiper/blob/Swiper5/API.md
      const options: SwiperOptions = {
        speed: 150,
        spaceBetween: 16,
        freeMode: true,
        grabCursor: true,
        mousewheel: { forceToAxis: true },
        keyboard: { enabled: true },
        a11y: {
          prevSlideMessage: this.$t("common.swiper.prevSlide") as string,
          nextSlideMessage: this.$t("common.swiper.nextSlide") as string,
          firstSlideMessage: this.$t("common.swiper.firstSlide") as string,
          lastSlideMessage: this.$t("common.swiper.lastSlide") as string,
        },
        ...this.options,
      }

      if (!this.noNavigation)
        options.navigation = {
          prevEl: `#${this.prevId}`,
          nextEl: `#${this.nextId}`,
          // @ts-ignore
          disabledClass: this.navDisabledClass || this.$style.navDisabled,
        }

      this.swiper = new Swiper(containerEl as HTMLElement, options)
    },
  },
})
