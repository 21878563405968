// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qKJHQDJe>*+*{margin-top:max(1.5rem,min(3.33vw,4rem))}.WAULav1W .swiper-container{--x:var(--p-x);--y:calc((100vw - var(--content-width))/2);padding:0 max(var(--x),var(--y))}._1FAu7ur\\+{height:auto!important;padding:2px;width:max(20.75rem,min(21.41vw,25.69rem))!important}._1FAu7ur\\+>*{height:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"xl": "1920.98",
	"container": "qKJHQDJe",
	"swiper": "WAULav1W",
	"swiperSlide": "_1FAu7ur+"
};
module.exports = ___CSS_LOADER_EXPORT___;
