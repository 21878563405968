import Vue from "vue"
import { getHtmlId } from "@/utils/_"

export default Vue.extend({
  data: () => ({
    prevId: "",
    nextId: "",
  }),

  mounted () {
    this.prevId = getHtmlId()
    this.nextId = getHtmlId()
  },
})
