
import Vue, { toRaw } from "vue"
import type { ProgramEventWithDate } from "./IndexCalendar.vue"
import { tagsPallete } from "@/utils/_"

export default Vue.extend({
  props: {
    event: {
      type: Object as VuePropType<ProgramEventWithDate>, required: true,
    },
  },

  data: () => ({
    tagsPallete: toRaw(tagsPallete),
  }),
})
