
import Vue from "vue"

export default Vue.extend({
  props: {
    linkTo: { type: [String, Object], default: "" },
    linkText: { type: String, default: "" },
    prevId: { type: String, required: true },
    nextId: { type: String, required: true },
  },
})
