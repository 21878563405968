import { render, staticRenderFns } from "./IndexCalendarMobile.vue?vue&type=template&id=1081bcb2"
import script from "./IndexCalendarMobile.vue?vue&type=script&lang=ts"
export * from "./IndexCalendarMobile.vue?vue&type=script&lang=ts"
import style0 from "./IndexCalendarMobile.vue?vue&type=style&index=0&id=1081bcb2&prod&module=true&lang=scss"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IndexCalendarEvent: require('/app/components/index/calendar/IndexCalendarEvent.vue').default,AppSwiper: require('/app/components/app/AppSwiper.vue').default,IndexSwiperFooter: require('/app/components/index/IndexSwiperFooter.vue').default})
